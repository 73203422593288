/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import Home from "./modules/home/index.vue";
import App from './App.vue'


Vue.use(ElementUI);
Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.prototype.$echarts = echarts;



// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
const routes = [
  {
    path: '/',
    redirect: { name: "form" }
  },
  {
    name: "form",
    path: '/form',
    component: Home,
  },
  {
    path: '*',
    redirect: { name: "form" }
  },
]
const router = new VueRouter({
  mode: 'history',
  base: '/tcm/',
  routes // (缩写) 相当于 routes: routes
})

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll(".el-radio__original")

    ariaEls.forEach(element => {
      element.removeAttribute('aria-hidden')
    });
  }
});


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
