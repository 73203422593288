<template>
  <div class="m-main-questionnaire">
    <div class="content">
      <el-row>
        <constitution></constitution>
      </el-row>
     
  </div>
    </div>
   
</template>

<script>
import constitution from './components/constitution.vue';
export default {
  name: "homePage",
  components:{
    constitution
  }
};
</script>

<style lang="stylus" scoped>
  .m-main-questionnaire {
    text-align: center;
    .content {
       margin-top: 50px;
    }
  }
</style>
