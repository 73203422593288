<template>
  <div style="position: relative; width: 100%; display: block;">
    <div id="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//import Home from "./modules/home/index.vue";

export default {
  name: "App",
  components: {
  },
};
</script>

